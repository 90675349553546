/* Default CSS variable values */
/* All CSS variables should be defined here with a sensible default value */
:root {
  /* Brand Colours */
  --wl-brand-color-one: #f1f1f1;
  --wl-brand-color-two: #666;
  --wl-brand-color-three: #fff;

  /* Backgrounds */
  --wl-background-color-default: #eaeaea;
  --wl-content-background-color-default: #ffffff;
  --wl-preload-background-image: url('./icons/preloader.gif') center center no-repeat var(--wl-content-background-color-default);
  --wl-background-color-one: #a0a0a0;
  --wl-background-color-two: #141414;
  --wl-background-color-three: #1e1e1e;
  --wl-background-color-four: #282828;
  --wl-background-color-five: #323232;
  --wl-background-color-six: #3c3c3c;
  --wl-background-color-seven: #464646;
  --wl-background-color-eight: #505050;
  --wl-background-color-nine: #000000;
  --wl-background-color-nine-double: #00000099;
  --wl-background-color-ten: rgba(0, 0, 0, 0.6);
  --wl-background-color-eleven: #f0f8ff;
  --wl-background-color-twelve: #bdc3c7;
  --wl-background-color-thirteen: #ecf0f1;
  --wl-background-color-fourteen: #fcfcfc;
  --wl-background-color-fifteen: #5da773;
  --wl-background-color-sixteen: rgba(34, 34, 34, 0.2);
  --wl-background-color-seventeen: #E28006;
  --wl-background-color-eighteen: #4A8AB0;
  --wl-background-color-nineteen: #3B96D2;
  --wl-background-color-twenty-three: #003580;
  --wl-background-color-twenty-two: #676767;
  --wl-button-disabled-background: gray;
  --tpp-button-pp-contact-background: var(--wl-background-color-one);
  --tpp-bdp-background-color: #f7f7f7;
  --tpp-srp-filters-background-color: #f7f7f7;
  --tpp-srp-filters-accent-color: #08244C;
  --tpp-srp-filters-dropdown-background-color: #FFF;
  --tpp-srp-filters-tool-set-background-color: #EAEAEA;
  --yw-header-v2-background-color-one: #FCFCFC;
  --yw-header-v2-background-color-two: #F3F6F6;

  /* Opaque backgrounds */
  --wl-background-opaque-default: rgba(255, 255, 255, 0.90);

  /* Texts */
  --wl-text-color-default: #333;
  --wl-text-color-link: #139af5;
  --wl-text-color-one: #d0d0d0;
  --wl-text-color-two: #171717;
  --wl-text-color-three: #212121;
  --wl-text-color-four: #2b2b2b;
  --wl-text-color-five: #353535;
  --wl-text-color-six: #3f3f3f;
  --wl-text-color-seven: #494949;
  --wl-text-color-eight: #535353;
  --wl-text-color-nine: #5d5d5d;
  --wl-text-color-ten: #676767;
  --wl-text-color-eleven: #717171;
  --wl-text-color-twelve: #7b7b7b;
  --wl-text-color-thirteen: #0d0d0d;
  --wl-text-color-fourteen: #373737;
  --wl-text-color-fifteen: #2b2b2b;
  --wl-text-color-sixteen: #FFFFFF;
  --wl-text-color-seventeen: #1261A2;
  --wl-text-color-eighteen: #33B96A;
  --wl-text-color-nineteen: #ED2E34;
  --wl-text-color-twenty: #3B96D2;
  --wl-text-color-twenty-one: #999999;
  --wl-text-color-twenty-two: #303030;
  --wl-text-color-twenty-three: #BDC3C7;
  --wl-text-color-twenty-four: #080808;
  --wl-text-color-disabled: white;
  --wl-background-default-footer: #EAEAEA;
  --tpp-bdp-text-color: #616161;
  --tpp-srp-filters-color: #303030;
  --tpp-srp-filters-mobile-filter-header-color: #003580;
  --yw-header-v2-text-color-one: #334557;
  --yw-header-v2-text-color-two: #07324F;
  --yw-header-v2-text-color-three: #35495D;
  --wl-text-color-invalid: #db1f22;

  /* Borders */
  --wl-border-color-default: #CCCCCC;
  --wl-border-color-one: #111111;
  --wl-border-color-two: #1b1b1b;
  --wl-border-color-three: #252525;
  --wl-border-color-four: #2f2f2f;
  --wl-border-color-five: #393939;
  --wl-border-color-six: #434343;
  --wl-border-color-seven: #4d4d4d;
  --wl-border-color-eight: #5d5d5d;
  --wl-border-color-nine: #676767;
  --wl-border-color-ten: rgba(255, 255, 255, 0.8);
  --tpp-li-pp-border-color: #e28006;
  --wl-border-color-eleven: silver;
  --wl-border-color-twelve: #003580;
  --wl-border-color-thirteen: #DEE2E3;
  --wl-border-color-fourteen: #eaeaea;
  --tpp-srp-filters-border-color: #dee2e3;
  --yw-header-v2-border-color-one: #F3F6F6;
  --yw-header-v2-border-color-two: #eff1f1;
  --yw-header-v2-border-color-three: #35495D;
  --wl-border-color-invalid: #db1f22;

  /* Box Shadow */
  --wl-box-shadow-one: rgba(255, 255, 255, 0.35);
  --wl-box-shadow-two: rgba(34, 34, 34, 0.5);
  --wl-box-shadow-three: rgba(0, 0, 0, 0.3);
  --wl-box-shadow-four: rgba(0, 0, 0, 0.15);
  --wl-box-shadow-five: rgba(0, 0, 0, 0);
  --wl-box-shadow-six: rgba(0, 0, 0, 0.25);
  --wl-box-shadow-seven: rgba(125, 125, 125, 1);
  --wl-box-shadow-eight: #0000001A;
  --wl-box-shadow-nine: #0000001F;
  --yw-header-v2-shadow-one: #4459581C;
  --yw-header-v2-shadow-two: #0000002E;

  /* Header */
  --wl-header-background-color: var(--wl-brand-color-one);
  --wl-header-button-background-color: var(--wl-brand-color-one);
  --wl-header-text-color: var(--wl-brand-color-three);
  --wl-header-hover-text-color: var(--wl-background-color-four);
  --wl-header-hover-background-color: var(--wl-background-color-sixteen);

  /* Home */
  --wl-blog-section-secondary-title-color: var(--wl-text-color-seventeen);

  /* Footer */
  --wl-footer-background-color: var(--wl-brand-color-three);
  --wl-footer-text-color: var(--wl-text-color-default);
  --wl-footer-link-color: var(--wl-text-color-link);

  /* Price color */
  --wl-price-text-color: var(--wl-text-color-eleven);

  /* Services */
  --wl-service-text-color: var(--wl-text-color-two);

  /* Pagination */
  --wl-pagination-text-color: var(--wl-text-color-five);

  /* Input boxes */
  --wl-input-box-border-color: var(--wl-border-color-default);

  /* Fonts */
  --wl-font-family-default: 'Lato', Arial, Helvetica, sans-serif;
  --wl-font-family-roboto: 'Roboto', Arial, Helvetica, sans-serif;

  /* font sizes */
  --wl-font-size-default: 15px;
  --wl-line-height-default: 122%;

  --wl-font-size-h1: 2rem;

  --wl-font-size-h2: 1.5rem;

  --wl-font-size-h3: 1.2rem;
  --wl-font-size-h3-large: 1.3rem;

  --wl-font-size-h4: 1rem;

  --wl-font-size-xxl-large: 100px;

  --wl-font-size-xl-large: 42px;

  --wl-font-size-xxxxx-large: 52px;

  --wl-font-size-xxxx-large: 28px;

  --wl-font-size-xxx-large: 26px;
  --wl-line-height-xxx-large: 180%;

  --wl-font-size-xx-large: 24px;
  --wl-line-height-xx-large: 160%;

  --wl-font-size-x-large: 20px;
  --wl-line-height-x-large: 150%;

  --wl-font-size-large: 18px;
  --wl-line-height-large: 140%;

  --wl-font-size-medium: 16px;
  --wl-line-height-medium: 130%;

  --wl-font-size-small: 14px;
  --wl-line-height-small: 110%;

  --wl-font-size-x-small: 13px;
  --wl-line-height-x-small: 100%;

  --wl-font-size-xx-small: 12px;
  --wl-line-height-xx-small: 90%;

  --wl-font-size-xxx-small: 10px;
  --wl-line-height-xxx-small: 85%;

  /* Heights */
  --wl-height-sticky-footer: 80px;

  /* Widths */
  --wl-max-content-width: 1256px;
  --wl-max-header-width: 1440px;

  /* Content */
  --wl-content-currency-symbol: "$";

  /* QuickSearch - Two cols layout */
  --wl-quicksearch-t-height: 306px;
  --wl-quicksearch-d-height: 356px;

  /* Listing attribute tag */
  --wl-listing-attribute-background-color: #E3F3FF;
  --wl-listing-attribute-text-color: #242424;

  /* BROKER BDP */

  --wl-text-bdp-color-link: #0077B3;
  --wl-text-bdp-color-link-hover: #21506F;
  --wl-text-bdp-color-breadcrumb: #404040;
  --wl-text-bdp-color-breadcrumb-mobile: #676767;

  /** END BROKER BDP **/
}
